import React from 'react'
import Abouthero from '../../component/Abouthero/Abouthero'
import Header from '../../component/header/Header'

const About = () => {
  return (
    <div>
        <Header />
        <Abouthero />
       
    </div>
  )
}

export default About
