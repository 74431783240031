import React from 'react'
import Header from '../../component/header/Header'
import Homehero from '../../component/Homehero/Homehero'

const Home = () => {
  return (
    <div>
      <Header />
     <Homehero />
       </div>
  )
}

export default Home