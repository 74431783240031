import React from 'react'
import NewsHero from '../../component/news/NewsHero'
import Mainheader from '../../component/Aboutheader/Mainheader'

const News = () => {
  return (
    <div>
      <Mainheader />
      <NewsHero />  
    </div>
  )
}

export default News