import React from 'react'
import Header from '../../header/Header'
import '../../styles/Production.css'
import Aboutheader from '../../Aboutheader/Aboutheader'
import OverlayImage from '../../../asset/PlayIcon-01.png'
import image from '../../../asset/production.jpg'


const Production = () => {
  return (
  
<section>
      <div className='bg-[--white] overflow-x-hidden'>
    <img src={image} alt="" />
  </div>
</section>
  );
}

export default Production;
