import React from 'react'
import image from '../../../asset/vas.png'
const Vas = () => {
  return (
    <section>
    <div className='bg-[--white] overflow-x-hidden'>
  <img src={image} alt="" />
</div>
</section>
    
  )
}

export default Vas;