import React from "react";
//import '../../styles/Home.css';

import image from '../../../asset/Home_page_About.png'

const Expermental = () => {
  return (
<section>
      <div className='bg-[--white] overflow-x-hidden'>
    <img src={image} alt="" />
  </div>
</section>
  );
};

export default Expermental;
