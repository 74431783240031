import React from 'react'
//import '../../styles/Digital.css'
import Aboutheader from '../../Aboutheader/Aboutheader'
import Header from '../../header/Header'
import image from '../../../asset/digital.jpg'
const Digital = () => {
  return (
    <section >
  
  <div className='bg-[--white] overflow-x-hidden'>
    <img src={image} alt="" />
  </div>
    </section>
  )
}

export default Digital