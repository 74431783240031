import React from 'react'
import image from '../../../asset/Research.png'

const Research = () => {

    return (
<section>
      <div className='bg-[--white] overflow-x-hidden'>
    <img src={image} alt="" />
  </div>
</section>
  )
}

export default Research