import React from 'react'
import Contacthero from '../../component/contact/Contacthero'
import Header from '../../component/header/Header'

const Contact = () => {
  return (
    <div>
      <Contacthero />
    </div>
  )
}

export default Contact